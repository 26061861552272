// ES5 way
// exports.onClientEntry = () => {
// ES6 way
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  // eslint-disable-next-line no-undef
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
  }
}
