// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-merci-js": () => import("./../src/pages/merci.js" /* webpackChunkName: "component---src-pages-merci-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-list-page-js": () => import("./../src/templates/article-list-page.js" /* webpackChunkName: "component---src-templates-article-list-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-legal-page-js": () => import("./../src/templates/legal-page.js" /* webpackChunkName: "component---src-templates-legal-page-js" */),
  "component---src-templates-missions-page-js": () => import("./../src/templates/missions-page.js" /* webpackChunkName: "component---src-templates-missions-page-js" */),
  "component---src-templates-project-page-js": () => import("./../src/templates/project-page.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-templates-testimonial-list-page-js": () => import("./../src/templates/testimonial-list-page.js" /* webpackChunkName: "component---src-templates-testimonial-list-page-js" */)
}

